import {
  FiGrid,
  FiShoppingBag,
  FiUsers,
  FiUser,
  FiCompass,
  FiGift,
  FiList,
  FiSettings,
  FiBus,
} from "react-icons/fi";
import { BsBusFront, BsFillChatDotsFill, BsMap } from "react-icons/bs";
import language from "../../language";
import { BiTrip } from "react-icons/bi";
import { AiOutlineUser } from "react-icons/ai";
import { FaFileInvoice } from "react-icons/fa";
import { MdRequestPage } from "react-icons/md";
/**
 * ⚠ These are used just to render the Sidebar!
 * You can include any link here, local or external.
 *
 * If you're looking to actual Router routes, go to
 * `routes/index.js`
 */
let languageSelectedEnglish =
  sessionStorage.getItem("language") === "english" ||
  !sessionStorage.getItem("language")
    ? true
    : false;

const sidebar = [
  {
    path: "/operator/dashboard", // the url
    icon: FiGrid, // icon
    name: `${languageSelectedEnglish ? "Dashboard" : language.dashboard}`,
  },
  {
    path: "/operator/buses",
    icon: BsBusFront,
    name: `${languageSelectedEnglish ? "Buses" : language.buses}`,
  },
  {
    path: "/operator/trips",
    icon: BiTrip,
    name: `${languageSelectedEnglish ? "Trips" : language.trips}`,
  },
  {
    path: "/operator/bookings",
    icon: FaFileInvoice,
    name: `${languageSelectedEnglish ? "Bookings" : language.bookings}`,
  },
  {
    path: "/operator/coupons",
    icon: FiGift,
    name: `${languageSelectedEnglish ? "Coupons" : language.coupons}`,
  },
  {
    path: "/operator/bus-stations",
    icon: BsMap,
    name: `${languageSelectedEnglish ? "Bus Stations" : language.busStations}`,
  },
  {
    path: "/operator/profile",
    icon: FiSettings,
    name: `${languageSelectedEnglish ? "Edit Profile" : language.editProfile}`,
  },
];

export default sidebar;
