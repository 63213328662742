import React, { useEffect, useState } from "react";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import Grid from "@mui/material/Grid";
import language from "../../../../language";
SwiperCore.use([Navigation]);

const CalendarSlider = () => {
  const today = new Date();
  const numberOfDays = 14; // Number of days to display
  let languageSelectedEnglish =
    sessionStorage.getItem("language") === "english" ||
    !sessionStorage.getItem("language")
      ? true
      : false;

  const [dates, setDates] = useState([]);

  useEffect(() => {
    const newDates = Array.from({ length: numberOfDays }, (_, index) => {
      const currentDate = new Date(today);
      if (window.screen.width >= 768) {
        currentDate.setDate(today.getDate() + index - 2);
      } else if (window.screen.width >= 450 && window.screen.width < 768) {
        currentDate.setDate(today.getDate() + index - 1);
      } else {
        currentDate.setDate(today.getDate() + index);
      }
      const dayOfWeek = currentDate.toLocaleString("en-US", {
        weekday: "short",
      });
      const dayOfMonth = currentDate.getDate();
      const month = currentDate.toLocaleString("default", { month: "short" });

      const dateClassName =
        currentDate < today
          ? "calendar-block disabled-date"
          : "calendar-block colored-dates";

      return {
        key: `${dayOfMonth}-${month}`,
        date: `${dayOfWeek} ${dayOfMonth} ${month}`,
        selected: false,
        className: dateClassName,
      };
    });

    setDates(newDates);
  }, []);

  const handleDateClick = (clickedDate) => {
    const tempDates = [...dates];
    const clickedIndex = tempDates.findIndex(
      (date) => date.date === clickedDate
    );

    if (
      clickedIndex !== -1 &&
      tempDates[clickedIndex].className !== "calendar-block disabled-date"
    ) {
      for (let i = 0; i < tempDates.length; i++) {
        tempDates[i].selected = false;
      }
      tempDates[clickedIndex].selected = true;
      setDates(tempDates);
    }
  };
  console.log("DATES", dates);
  return (
    <Grid item xs={12} align="center" className="border-wrap">
      <p className="date-selection-title">
        {languageSelectedEnglish ? "Select A Date" : language.selectADate}
      </p>
      <Swiper
        spaceBetween={10}
        slidesPerView={5}
        navigation
        breakpoints={{
          768: {
            slidesPerView: 5,
          },
          450: {
            slidesPerView: 3,
          },
          100: {
            slidesPerView: 1,
          },
        }}
      >
        {dates?.map((date) => {
          let tempDateSplit = date.date.split(" ");
          let todayDate =
            parseInt(today.toDateString().split(" ")[2]) ==
            parseInt(date.date.split(" ")[1]);

          return (
            <SwiperSlide key={date.key}>
              <div
                className={
                  date.selected
                    ? "calendar-block selected-date"
                    : date.className
                }
                onClick={() => handleDateClick(date.date)}
                style={{
                  border: `${todayDate ? "2px solid #007aff" : ""}`,
                }}
              >
                <div className="day-of-week">{tempDateSplit[0]}</div>
                <div className="day-of-month">
                  {tempDateSplit[1]} {tempDateSplit[2]}
                </div>
                <div className="month">
                  {languageSelectedEnglish ? "From" : language.from}{" "}
                  <span>SAR 159</span>.00
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Grid>
  );
};

export default CalendarSlider;
