import dayjs from "dayjs";
import { useParams } from "react-router";
import ReactToPrint from "react-to-print";
import React, {
  useContext,
  useRef,
  useCallback,
  useState,
  useEffect,
} from "react";
import { FiPrinter } from "react-icons/fi";
import QRCode from "react-qr-code";
import "./InvoiceUser.css";
import { IoCloudDownloadOutline } from "react-icons/io5";
import {
  TableCell,
  TableHeader,
  Table,
  TableContainer,
  WindmillContext,
} from "@windmill/react-ui";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Cookies from "universal-cookie";
// MUI
import Grid from "@mui/material/Grid";
// import Status from '../components/table/Status';
import Status from "../../../admin/components/table/Status";
// import Invoice from '../components/invoice/Invoice';
import Invoice from "../../../admin/components/invoice/Invoice";
// import logoDark from '../assets/img/logo/logo-dark.svg';
// import logoDark from './../../../admin/assets/img/logo/logoDark.svg'
// import logoLight from './../admin/assets/img/logo/logo-light.svg';
// import PageTitle from '../components/Typography/PageTitle';
import PageTitle from "../../../operator/components/Typography/PageTitle";
import { useSelector, useDispatch } from "react-redux";
// API
import { bookingBySearch } from "../../../Redux/userReducer";
import language from "../../../language";
const InvoiceUser = () => {
  const [bookingData, setBookingData] = useState();
  let languageSelectedEnglish =
    sessionStorage.getItem("language") === "english" ||
      !sessionStorage.getItem("language")
      ? true
      : false;

  const dispatch = useDispatch();
  const { mode } = useContext(WindmillContext);
  const { id } = useParams();
  const printRef = useRef();
  const cookies = new Cookies();

  // Redux
  const searchedData = useSelector((state) => state?.userReducer?.searchDataH2);
  const bookingId = useSelector((state) => state?.userReducer?.bookingId);
  const priceData = useSelector((state) => state?.userReducer?.priceDataH2);

  console.log("bookingIdbookingId", bookingId);

  let result = bookingId;

  console.log("bookingIdbookingId", result, priceData);
  const isLogin = cookies.get("token");

  const QRData = {
    // bookingId: bookingId,
    // fareAmount: priceData.fareAmount,
    // noOfSeats: priceData.passengerNumber,
    // departureCity: priceData.departureCity,
    // arrivalCity: priceData.arrivalCity,
    // departureTime: priceData.departure,
    // arrivalTime: priceData.arrival,
    // date: priceData.date,
  };

  // If bookingID is successfully received
  useEffect(() => {
    const getBookingFunction = async () => {
      // Get Booking By ID
      console.log(bookingId);
      const response = await dispatch(bookingBySearch(bookingId));
      setBookingData(response.payload);
    };
    getBookingFunction();
  }, []);

  console.log(bookingData);

  return (
    <>
      <div className="mainContainerInvoice">
        <div className="mainContainerInvoiceInner">
          <div
            ref={printRef}
            className="bg-white mb-4 p-6 lg:p-8 rounded-xl shadow-sm overflow-hidden invoicPrintable"
          >
            <Grid container className="" marginTop="50px">
              <Grid item xs={12} display="flex" justifyContent="center">
                <Grid container xs={11} justifyContent="space-between">
                  <Grid item xs={12}>
                    <p className="travel-itinerary-main text-[20px] text-[#5D0139] font-bold">
                      {languageSelectedEnglish
                        ? "Travel Itinerary"
                        : language.travelItinerary}
                    </p>
                  </Grid>

                  <Grid item sm={6} lg={3} className="p-2 flex justify-between gap-[10px]">
                    <div className="flex flex-col pb-4 border-b border-gray-50">
                      <h1 className="title-travel-status">
                        {languageSelectedEnglish
                          ? "Travel Itinerary"
                          : language.travelItinerary}

                        <p className="statusSection">
                          {languageSelectedEnglish
                            ? "Status:"
                            : language.status}{" "}
                          <span className="pl-2 confirmText">
                            {" "}
                            <Status status={"Confirmed"} />
                            Confirmed
                          </span>
                        </p>
                        <div className="pt-2">
                          <QRCode
                            value={JSON.stringify(QRData)}
                            level="M"
                            size={230}
                            fgColor="#5D0139"
                          />
                        </div>
                      </h1>
                      <div className="lg:text-right text-left">
                        <p className="travel-deadline">
                          {searchedData?.to}
                          {/* {languageSelectedEnglish ? "to" : language.to}{" "} */}
                          Lahore to Multan
                          {searchedData?.from}
                          <br /> in 5 hours
                        </p>
                      </div>
                    </div>
                    <div className="divider "></div>
                  </Grid>

                  <Grid item sm={6} lg={3} className="p-2">
                    <div className="flex flex-col">
                      <div className="mb-3 md:mb-0 lg:mb-0 flex flex-col">
                        <span className="numberText">
                          {languageSelectedEnglish
                            ? "Travel Itinerary"
                            : language.travelItinerary}{" "}
                          Number <br />
                        </span>
                        <span className="alotNo">{result}AA121243</span>
                      </div>
                      <div className="mb-3 md:mb-0 lg:mb-0 flex flex-col">
                        <span className="numberText">
                          {languageSelectedEnglish ? "Itinerary Issue Date" : language.date}
                          <br />
                        </span>
                        <span className="dateText">
                          {bookingData !== undefined && (
                            <span>
                              {dayjs(bookingData?.createdAt).format(
                                "MMMM D, YYYY"
                              )}
                            </span>
                          )}
                          <span> April 15, 2023</span>
                        </span>
                      </div>
                      <div className="mb-3 md:mb-0 lg:mb-0 flex flex-col">
                        <span className="numberText">
                          Trip ID
                          <br />
                        </span>
                        <span className="dateText">
                          <span>efd8e0</span>
                        </span>
                      </div>

                      <div className="flex flex-col">
                        <span className="numberText">
                          {languageSelectedEnglish
                            ? "Travel Itinerary"
                            : language.travelItinerary}{" "}
                          for <br />
                        </span>
                        <span className="dateText operator1">
                          {bookingData?.passengerDetails[0]?.firstName}{" "}
                          {bookingData?.passengerDetails[0]?.lastName}
                          Muhammad Abdullah   <br />
                          House no 3   <br />
                          street 34 Newyork
                        </span>
                        <span className="text-details-invoice">
                          <span className="numberText">
                            Departure Date & Time<br />
                          </span>
                          <span className="dateText">
                            Lahore<br />
                            22/08/2023<br />
                            9:41pm
                          </span>
                        </span>
                        <span
                          style={{ marginTop: "20px" }}
                          className="text-details-invoice">
                          <span className="numberText">
                            Departure Location<br />
                          </span>
                          <a

                            target="_blank"
                            href='
                        https://www.google.com/maps/search/?api=1&query=29.9993536,73.25497299999999
                        '
                            className="linkText"
                            style={{ wordWrap: 'break-word' }}
                          >
                            https://www.google.com/maps/search/?api=1&query=29.9993536,73.25497299999999
                          </a>
                        </span>
                      </div>
                    </div>
                  </Grid>

                  <Grid item sm={6} lg={3} className="p-2">
                    <div className="flex flex-col operator">
                      <span className="numberText">
                        Operator
                      </span>
                      <span className="dateText">
                        Faisal Movers
                      </span>
                    </div>
                    <div className="flex flex-col operator">
                      <span className="numberText">
                        Bus Type
                      </span>
                      <span className="dateText">
                        Business class
                      </span>
                    </div>
                    <div className="flex flex-col operator">
                      <span className="numberText">
                        Bus Plate Number
                      </span>
                      <span className="dateText">
                        123321
                      </span>
                    </div>
                    <div className="flex flex-col operator">
                      <span className="numberText">
                        No of Seats
                      </span>
                      <span className="dateText">
                        03
                      </span>
                    </div>
                    <div className="flex flex-col operator">
                      <span className="numberText">
                        Selected Seat Numbers
                      </span>
                      <span className="dateText">
                        1,2,3
                      </span>
                    </div>
                    <div className="flex flex-col ">
                      <span className="numberText">
                        Arrival Date & Time
                      </span>
                      <span className="dateText">
                        Multan <br />
                        22/08/2023 <br />
                        9:41pm
                      </span>
                      <span
                        style={{ marginTop: "20px" }}
                        className="text-details-invoice">
                        <span className="numberText">
                          Arrival  Location<br />
                        </span>
                        <a
                          target="_blank"
                          href='
                        https://www.google.com/maps/search/?api=1&query=29.9993536,73.25497299999999
                        ' className="linkText"
                          style={{ wordWrap: 'break-word' }}
                        >
                          https://www.google.com/maps/search/?api=1&query=29.9993536,73.25497299999999
                        </a>
                      </span>
                    </div>
                  </Grid>

                  <Grid item sm={6} lg={3} className="p-2">
                    <div>
                      <p
                        className="invoiceText"
                      >
                        {languageSelectedEnglish
                          ? "Invoice Number:"
                          : language.seatNumber}
                        <span className="invoiveNoText">
                          {bookingData?.seats.length > 1
                            ? bookingData?.seats.map((seatNo, index) => (
                              <span
                                key={index}
                                style={{ fontWeight: 600, color: "#BE892A" }}
                              >
                                {seatNo}
                                {index !== bookingData?.seats.length - 1 &&
                                  " & "}
                              </span>
                            ))
                            : bookingData?.seats[0]}
                          224
                        </span>
                      </p>
                    </div>
                    <div className="border-payment">
                      <div className="flex flex-col">
                        <div className="mb-3 md:mb-0 lg:mb-0  flex flex-col sm:flex-wrap">
                          <span className="numberText">
                            {languageSelectedEnglish
                              ? "Payment Method"
                              : language.paymentMethod}
                            <br />
                          </span>
                          <span className="creditText">
                            {/* {data.paymentMethod} */}

                            {languageSelectedEnglish
                              ? "Credit"
                              : language.credit}
                          </span>
                        </div>
                        <div className="mb-3 md:mb-0 lg:mb-0  flex flex-col sm:flex-wrap">
                          <span className="numberText">
                            {languageSelectedEnglish ? "Fare" : language.fare}{" "}
                            <br />
                          </span>
                          <span className="creditText">
                            ${Math.round(bookingData?.price)}.00
                          </span>
                        </div>
                        <div className="mb-3 md:mb-0 lg:mb-0  flex flex-col sm:flex-wrap">
                          <span className="numberText">
                            {languageSelectedEnglish
                              ? "Discount"
                              : language.discount}{" "}
                            <br />
                          </span>
                          <span className="creditText">
                            {/* ${Math.round(10.93)}.00 */}
                            00.00
                          </span>
                        </div>
                        <div className="flex flex-col sm:flex-wrap">
                          <span className="numberText">
                            {languageSelectedEnglish
                              ? "Total Amount Paid"
                              : language.totalAmountPaid}
                            <br />
                          </span>
                          <span className="creditText">
                            ${Math.round(bookingData?.price)}.00
                            {/* {priceData?.fareAmount} */}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="mb-4 mt-3 flex gap-[15px] flex-wrap justify-between topButtonInvoice">
                      <PDFDownloadLink
                        // document={<InvoiceForDownload data={data} />}
                        fileName="Invoice"
                      >
                        <button className="flex items-center downBtn text-sm leading-5 transition-colors duration-150 font-medium focus:outline-none px-5 py-2 rounded-md text-white bg-green-500 border border-transparent active:bg-green-600 hover:bg-green-600 focus:ring focus:ring-purple-300 w-auto cursor-pointer">
                          Download Travel itinerary{" "}
                          <span className="ml-2 text-base">
                            <IoCloudDownloadOutline />
                          </span>
                        </button>
                      </PDFDownloadLink>

                      <ReactToPrint
                        trigger={() => (
                          <button
                            className="flex items-center text-[20px] downBtn text-sm leading-5 transition-colors duration-150 font-medium focus:outline-none px-5 py-2 rounded-md text-white bg-green-500 border border-transparent active:bg-green-600 hover:bg-green-600 focus:ring focus:ring-purple-300 w-auto  printInvoiceBtn"
                          >
                            {languageSelectedEnglish
                              ? "Print or Save Travel Itinerary"
                              : language.printOrSaveTravelItinerary}{" "}
                            <span className="ml-2">
                              <FiPrinter />
                            </span>
                          </button>
                        )}
                        content={() => printRef.current}
                        documentTitle="Invoice"
                      />
                    </div>
                  </Grid>
                </Grid >
              </Grid >
            </Grid >
          </div >
        </div >
      </div >
    </>
  );
};

export default InvoiceUser;
