import React, { useContext, useState } from "react";
import { Button } from "@windmill/react-ui";
import { useDispatch } from "react-redux";
import "./EditProfile.css";
import { updateUser } from "../../Redux/userReducer";
import Swal from "sweetalert2";
import EditProfileMap from "./EditProfileMap";
import Error from "../components/form/Error";
import { useSelector } from "react-redux/es/exports";
import useStaffSubmit from "../hooks/useStaffSubmit";
import LabelArea from "../components/form/LabelArea";
import InputArea from "../components/form/InputArea";
import { AdminContext } from "../context/AdminContext";
import SelectRole from "../components/form/SelectRole";
import GoogleMapReact from "google-map-react";
import language from "../../language";
// import { getUser } from "../../../Redux/userReducer";
import { getUser } from "../../Redux/userReducer";
import PageTitle from "../components/Typography/PageTitle";
import Uploader from "../components/image-uploader/Uploader";
import { useEffect } from "react";
import { updateOperator } from "../../Redux/userReducer";
import { getAllOperators } from "../../actions/operators";
import store from "../../store";
import { connect } from "react-redux";
import RoomIcon from "@mui/icons-material/Room";

const AnyReactComponent = ({ text }) => (
  <div style={{ width: "30px", height: "30px" }}>
    <RoomIcon style={{ width: "100%", height: "100%", color: "#22C55E" }} />
  </div>
);
const EditProfile = ({ operators: { operators } }) => {
  const dispatch = useDispatch();
  const userDataStore = useSelector(
    (state) => state?.userReducer?.userData?.user
  );
  let currentoperator = operators?.filter(
    (operator) => operator.userId === userDataStore?._id
  );
  if (currentoperator) {
    currentoperator = currentoperator[0];
  }

  console.log(currentoperator);
  /*   const [userData, setUserData] = useState(); */
  console.log("likeLikeOO", userDataStore);
  const [user, setUser] = useState({
    companyname: currentoperator ? currentoperator.companyname : "",
    username: currentoperator?.username ? currentoperator?.username : "",
    contactnumber: currentoperator?.contactnumber
      ? currentoperator?.contactnumber
      : "",
    email: currentoperator?.email ? currentoperator?.email : "",
    password: "",
    companyaddress: currentoperator?.companyaddress
      ? currentoperator?.companyaddress
      : "",
    companycontactnumber: currentoperator?.companycontactnumber
      ? currentoperator?.companycontactnumber
      : "",
    companylocation: currentoperator?.companylocation
      ? currentoperator?.companylocation
      : {
          lat: null,
          lng: null,
        },
  });
  /*   const [user, setUser] = useState({
    companyname: userData?.companyname,
    username: userData?.username ? userData?.username : "",
    contactnumber: userData?.contactnumber ? userData?.contactnumber : "",
    email: userData?.email ? userData?.email : "",
    password: "",
    companyaddress: userData?.companyaddress ? userData?.companyaddress : "",
    companycontactnumber: userData?.companycontactnumber
      ? userData?.companycontactnumber
      : "",
    companylocation: userData?.companylocation ? userData?.companylocation : "",
  }); */
  console.log("user", user);
  const [logo, setLogo] = useState();
  const defaultProps = {
    center: {
      lat: 23.424076, // Default latitude for UAE
      lng: 53.847818, // Default longitude for UAE
    },
    zoom: 7,
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    setUser({
      ...user,
      [name]: value,
    });
  };
  const {
    state: { adminInfo },
  } = useContext(AdminContext);
  let languageSelectedEnglish =
    sessionStorage.getItem("language") === "english" ||
    !sessionStorage.getItem("language")
      ? true
      : false;

  const { register, handleSubmit, onSubmit, errors, imageUrl, setImageUrl } =
    useStaffSubmit(adminInfo?._id);

  const submitUpdate = async () => {
    const dataImage = new FormData();
    dataImage.append("file", logo);
    dataImage.append("upload_preset", "u928wexc");
    dataImage.append("cloud_name", "dihkvficg");

    const res = await fetch(
      "https://api.cloudinary.com/v1_1/dihkvficg/image/upload",
      {
        method: "post",
        body: dataImage,
      }
    );

    const resData = await res.json();
    const image_url = resData.url;

    const data = {
      image: image_url,
      companyname: user.companyname,
      username: user.username,
      contactnumber: user.contactnumber,
      email: user.email,
      password: user.password,
      companyaddress: user.companyaddress,
      companycontactnumber: user.companycontactnumber,
      companylocation: user.companylocation,
    };
    const response = await dispatch(updateOperator(data));

    console.log("responseUpdateOperator", response);

    if (response?.payload?.data?.success) {
      Swal.fire({
        icon: "success",
        title: "",
        text: "Operator Updated",
      });
    }
  };

  useEffect(() => {
    store.dispatch(getAllOperators());
    handleGetUserData();
  }, []);
  useEffect(() => {
    setUser({
      companyname: currentoperator ? currentoperator.companyname : "",
      username: currentoperator?.username ? currentoperator?.username : "",
      contactnumber: currentoperator?.contactnumber
        ? currentoperator?.contactnumber
        : "",
      email: userDataStore ? userDataStore?.email : "",
      password: "",
      companyaddress: currentoperator?.companyaddress
        ? currentoperator?.companyaddress
        : "",
      companycontactnumber: currentoperator?.companycontactnumber
        ? currentoperator?.companycontactnumber
        : "",
      companylocation: currentoperator?.companylocation
        ? currentoperator?.companylocation
        : {
            lat: null,
            lng: null,
          },
    });
  }, [currentoperator]);
  const handleGetUserData = async () => {
    store.dispatch(getUser());
    /*  console.log(res);
    const currentoperator = operators?.filter(
      (operator) => operator.userId === res.payload.user._id
    );
    if (currentoperator) {
      setUserData(currentoperator[0]);
    } */
  };
  return (
    <>
      <PageTitle>
        {" "}
        {languageSelectedEnglish ? "Edit Profile" : language.editProfile}
      </PageTitle>
      <div className="container p-6 mx-auto bg-white rounded-lg">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="p-6 flex-grow scrollbar-hide w-full max-h-full">
            <div className="grid grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6">
              <LabelArea
                label={
                  languageSelectedEnglish
                    ? "Company Logo"
                    : language.companyLogo
                }
              />
              <div className="col-span-8 sm:col-span-4">
                <input
                  id="file-uploader"
                  style={{ display: "block" }}
                  type="file"
                  onChange={(e) => setLogo(e.target.files[0])}
                />
                <img
                  src={currentoperator?.image}
                  style={{ marginTop: "10px" }}
                />
              </div>
            </div>
            {/*    <div className="grid grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6">
              <LabelArea label="Username" />
              <div className="col-span-8 sm:col-span-4">
                <InputArea
                  register={register}
                  label="Username"
                  name="username"
                  defaultValue={user.username}
                  onChange={(e) => handleChange(e)}
                  type="text"
                  placeholder="Username"
                />
                <Error errorName={errors.name} />
              </div>
            </div> */}

            <div className="grid grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6">
              <LabelArea
                label={languageSelectedEnglish ? "Email" : language.email}
              />
              <div className="col-span-8 sm:col-span-4">
                <InputArea
                  register={register}
                  label={languageSelectedEnglish ? "Email" : language.email}
                  /*    value={user.email} */
                  onChange={(e) => handleChange(e)}
                  name="email"
                  defaultValue={user.email}
                  type="text"
                  placeholder={
                    languageSelectedEnglish ? "Email" : language.email
                  }
                />
                <Error errorName={errors.email} />
              </div>
            </div>
            <div className="grid grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6">
              <LabelArea
                label={languageSelectedEnglish ? "Password" : language.password}
              />
              <div className="col-span-8 sm:col-span-4">
                <InputArea
                  register={register}
                  label={
                    languageSelectedEnglish ? "Password" : language.password
                  }
                  name="password"
                  type="password"
                  defaultValue={user.password}
                  onChange={(e) => handleChange(e)}
                  placeholder={
                    languageSelectedEnglish ? "Password" : language.password
                  }
                />
                <Error errorName={errors.role} />
              </div>
            </div>
            <div className="grid grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6">
              <LabelArea
                label={
                  languageSelectedEnglish
                    ? "Contact Number"
                    : language.contactnumber
                }
              />
              <div className="col-span-8 sm:col-span-4">
                <InputArea
                  register={register}
                  label={
                    languageSelectedEnglish
                      ? "Contact Number"
                      : language.contactnumber
                  }
                  name="contactnumber"
                  defaultValue={user?.contactnumber}
                  onChange={(e) => handleChange(e)}
                  type="text"
                  placeholder={
                    languageSelectedEnglish
                      ? "Contact Number"
                      : language.contactnumber
                  }
                />
                <Error errorName={errors.phone} />
              </div>
            </div>
            <div className="grid grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6">
              <LabelArea
                label={
                  languageSelectedEnglish
                    ? "Company Name"
                    : language.companyName
                }
              />
              <div className="col-span-8 sm:col-span-4">
                <InputArea
                  register={register}
                  label={
                    languageSelectedEnglish
                      ? "Company Name"
                      : language.companyName
                  }
                  name="companyname"
                  defaultValue={user.companyname}
                  onChange={(e) => handleChange(e)}
                  type="text"
                  placeholder={
                    languageSelectedEnglish
                      ? "Company Name"
                      : language.companyName
                  }
                />
                <Error errorName={errors.name} />
              </div>
            </div>
            <div className="grid grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6">
              <LabelArea
                label={
                  languageSelectedEnglish
                    ? "Company Representative's Name"
                    : language.companyRepresentativeName
                }
              />
              <div className="col-span-8 sm:col-span-4">
                <InputArea
                  register={register}
                  label={
                    languageSelectedEnglish
                      ? "Company Representative's Name"
                      : language.companyRepresentativeName
                  }
                  name="username"
                  defaultValue={user.username}
                  onChange={(e) => handleChange(e)}
                  type="text"
                  placeholder={
                    languageSelectedEnglish
                      ? "Company Representative's Name"
                      : language.companyRepresentativeName
                  }
                />
                <Error errorName={errors.name} />
              </div>
            </div>

            <div className="grid grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6">
              <LabelArea
                label={
                  languageSelectedEnglish
                    ? "Company Head Office Address"
                    : language.companyHeadOfficeAddress
                }
              />
              <div className="col-span-8 sm:col-span-4">
                <InputArea
                  register={register}
                  label={
                    languageSelectedEnglish
                      ? "Company Head Office Address"
                      : language.companyHeadOfficeAddress
                  }
                  name="companyaddress"
                  defaultValue={user.companyaddress}
                  onChange={(e) => handleChange(e)}
                  type="text"
                  placeholder={
                    languageSelectedEnglish
                      ? "Company Head Office Address"
                      : language.companyHeadOfficeAddress
                  }
                />
                <Error errorName={errors.name} />
              </div>
            </div>
            <div className="grid grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6">
              <LabelArea
                label={
                  languageSelectedEnglish
                    ? "Company Head Office Phone Number"
                    : language.companyHeadOfficePhoneNumber
                }
              />
              <div className="col-span-8 sm:col-span-4">
                <InputArea
                  register={register}
                  label={
                    languageSelectedEnglish
                      ? "Company Head Office Phone Number"
                      : language.companyHeadOfficePhoneNumber
                  }
                  name="companycontactnumber"
                  defaultValue={user.companycontactnumber}
                  onChange={(e) => handleChange(e)}
                  type="text"
                  placeholder={
                    languageSelectedEnglish
                      ? "Company Head Office Phone Number"
                      : language.companyHeadOfficePhoneNumber
                  }
                />
                <Error errorName={errors.name} />
              </div>
            </div>
            <div className="grid grid-cols-6 gap-3 md:gap-5 xl:gap-6 lg:gap-6 mb-6">
              <LabelArea
                label={
                  languageSelectedEnglish
                    ? "Company Head Office Location"
                    : language.companyHeadOfficeLocation
                }
              />

              <EditProfileMap user={user} setUser={setUser} />
            </div>
          </div>
          {user?.companylocation.lat && (
            <div className="mapScreenshot">
              <div
                style={{
                  marginBottom: "15px",
                }}
              >
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${user.companylocation.lat},${user.companylocation.lng}`}
                  target="_blank"
                  style={{
                    marginBottom: "15px",
                  }}
                >
                  https://www.google.com/maps/search/?api=1&query=
                  {user.companylocation.lat},{user.companylocation.lng}
                </a>
              </div>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyA_9dyGB-Du4nrXiyVLx_Ice7c93V-JOGY",
                }}
                defaultCenter={defaultProps.center}
                defaultZoom={15}
                center={user.companylocation}
                zoom={15}
                /* onClick={handleMapClick} */
              >
                {/*   {marker && (
                      <AnyReactComponent lat={marker.lat} lng={marker.lng} />
                    )} */}

                <AnyReactComponent
                  lat={user.companylocation.lat}
                  lng={user.companylocation.lng}
                />
              </GoogleMapReact>
            </div>
          )}
          <div className="flex flex-row-reverse pr-6 pb-6">
            <Button
              // type="submit"
              className="h-12 px-6 updateBtn"
              onClick={submitUpdate}
            >
              {languageSelectedEnglish
                ? "Update Profile"
                : language.updateProfile}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  operators: state.operators,
});

export default connect(mapStateToProps, null)(EditProfile);
