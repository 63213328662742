import React from "react";
import Grid from "@mui/material/Grid";
import { BiLogoGoogle, BiLogoFacebookCircle } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import language from "../../../language";
const Signup = ({ setStatusToSignin }) => {
  const history = useHistory();
  let languageSelectedEnglish =
    sessionStorage.getItem("language") === "english" ||
    !sessionStorage.getItem("language")
      ? true
      : false;

  return (
    <div className="width-wrap">
      <Grid container className="signup-wrapper">
        <Grid item xs={12} align="center" className="">
          <h3 className="signup-welcome">
            {languageSelectedEnglish ? "Welcome!" : language.welcome}
          </h3>
          <p className="signup-pre">
            {languageSelectedEnglish
              ? "Sign up for an account"
              : language.signUpForAnAccount}
          </p>
          <div className="signup-form">
            <input
              type="text"
              placeholder={languageSelectedEnglish ? "Email" : language.email}
            />
            <input
              type="text"
              placeholder={
                languageSelectedEnglish ? "Password" : language.password
              }
            />
            <input
              type="text"
              placeholder={
                languageSelectedEnglish
                  ? "Confirm Password"
                  : language.confirmPassword
              }
            />
          </div>
          {/* <Grid item xs={12} align="end">
                    <p className="forgot-password">Forgot your password</p>
                </Grid> */}
          <button className="button-signup">
            {languageSelectedEnglish ? "Sign up" : language.signUp}
          </button>
          <p className="or-text">
            {languageSelectedEnglish ? "OR" : language.or}
          </p>
          <button className="button-signup-google">
            <div className="">
              <BiLogoGoogle
                style={{
                  color: "#4889F4",
                  fontSize: "20px",
                  marginRight: "5px",
                }}
              />
            </div>
            <div>
              {languageSelectedEnglish
                ? "Sign up with Google"
                : language.signUpWithGoogle}
            </div>
          </button>
          <button className="button-signup-facebook">
            <div className="">
              <BiLogoFacebookCircle
                style={{ color: "#fff", fontSize: "20px", marginRight: "5px" }}
              />
            </div>
            <div>
              {languageSelectedEnglish
                ? "Sign up with Facebook"
                : language.signUpWithFacebook}
            </div>
          </button>
          <Grid item xs={12} align="right">
            <p className="signin-pre">
              {languageSelectedEnglish
                ? "Already a member?"
                : language.alreadyAMember}

              <span
                onClick={setStatusToSignin}
                style={{
                  cursor: "pointer",
                }}
              >
                {languageSelectedEnglish ? "Go sign in" : language.goSignIn}
              </span>
            </p>
            <p className="signin-pre">
              {languageSelectedEnglish
                ? "Are you a Transport Company? Go"
                : language.areYouATransportCompany}
              <span
                onClick={() => history.push("/operator/login")}
                style={{
                  cursor: "pointer",
                }}
              >
                {languageSelectedEnglish ? " sign in " : language.signIn}
              </span>
              {languageSelectedEnglish ? "or" : language.or}

              <span
                onClick={() => history.push("/operator/signup")}
                style={{
                  cursor: "pointer",
                }}
              >
                {languageSelectedEnglish ? " Sign Up " : language.signUp}
              </span>
            </p>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Signup;
