import React, { useEffect } from "react";
import "./VideoCall.scss";
import AgoraRTM from "agora-rtm-sdk";
import camera from "../../../assets/camera.png";
import mic from "../../../assets/mic.png";
import phone from "../../../assets/phone.png";
//other
import { getUser } from "../../../Redux/userReducer";
import store from "../../../store";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux/es/exports";
const VideoCall = ({ handleClose }) => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.userReducer?.userData?.user);
  const APP_ID = "8e8bbdbece924fc79a460ef46dced1fa";
  const token = null;
  const uid = String(Math.floor(Math.random() * 10000));

  let client;
  let channel;
  let localStream;
  let remoteStream;
  let peerConnection;

  const servers = {
    iceServers: [
      {
        urls: [
          "stun:stun1.l.google.com:19302",
          "stun:stun2.l.google.com:19302",
        ],
      },
    ],
  };
  /*   let constraints = {
    video: false,
    audio: true,
  }; */
  useEffect(() => {
    let constraints = {
      video: true,
      audio: true,
    };
    const init = async () => {
      client = await AgoraRTM.createInstance(APP_ID);
      await client.login({ uid, token });
      channel = client.createChannel("main");
      await channel.join();

      channel.on("MemberJoined", handleUserJoined);
      channel.on("MemberLeft", handleUserLeft);
      client.on("MessageFromPeer", handleMessageFromPeer);

      localStream = await navigator.mediaDevices.getUserMedia(constraints);

      document.getElementById("user-1").srcObject = localStream;

      document.getElementById("camera-btn").style.backgroundColor =
        "rgb(255,80,80)";
      let videoTrack = localStream
        .getTracks()
        .find((track) => track.kind === "video");
      videoTrack.enabled = false;
    };

    const handleUserLeft = (MemberId) => {
      document.getElementById("user-2").style.display = "none";
      document.getElementById("user-1").classList.remove("smallFrameClient");
    };

    const handleMessageFromPeer = async (message, MemberId) => {
      message = JSON.parse(message.text);
      if (message.type === "offer") {
        createAnswer(MemberId, message.offer);
      }
      if (message.type === "answer") {
        addAnswer(message.answer);
      }
      if (message.type === "candidate") {
        if (peerConnection) {
          peerConnection.addIceCandidate(message.candidate);
        }
      }
    };

    const handleUserJoined = async (MemberId) => {
      /*  if (userData?.role === "admin") { */
      handleNotify();

      toast.success("User has Joined!", { position: "top-center" });

      /*  } */

      console.log("NEW USER JOINED CHANNEL", MemberId);
      createOffer(MemberId);
    };

    const createPeerConnection = async (MemberId) => {
      peerConnection = new RTCPeerConnection(servers);
      remoteStream = new MediaStream();
      document.getElementById("user-2").srcObject = remoteStream;
      document.getElementById("user-2").style.display = "block";

      document.getElementById("user-1").classList.add("smallFrameClient");

      if (!localStream) {
        localStream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true,
        });
        document.getElementById("user-1").srcObject = localStream;
      }

      localStream.getTracks().forEach((track) => {
        peerConnection.addTrack(track, localStream);
      });

      peerConnection.ontrack = (event) => {
        event.streams[0].getTracks().forEach((track) => {
          remoteStream.addTrack(track);
        });
      };

      peerConnection.onicecandidate = async (event) => {
        if (event.candidate) {
          client.sendMessageToPeer(
            {
              text: JSON.stringify({
                type: "candidate",
                candidate: event.candidate,
              }),
            },
            MemberId
          );
        }
      };
    };

    const createOffer = async (MemberId) => {
      await createPeerConnection(MemberId);
      let offer = await peerConnection.createOffer();
      await peerConnection.setLocalDescription(offer);

      client.sendMessageToPeer(
        { text: JSON.stringify({ type: "offer", offer: offer }) },
        MemberId
      );
    };

    const createAnswer = async (MemberId, offer) => {
      await createPeerConnection(MemberId);

      await peerConnection.setRemoteDescription(offer);

      let answer = await peerConnection.createAnswer();
      await peerConnection.setLocalDescription(answer);

      client.sendMessageToPeer(
        { text: JSON.stringify({ type: "answer", answer: answer }) },
        MemberId
      );
    };

    const addAnswer = async (answer) => {
      if (!peerConnection.currentRemoteDescription) {
        peerConnection.setRemoteDescription(answer);
      }
    };

    const leaveChannel = async () => {
      await channel.leave();
      await client.logout();
    };

    window.addEventListener("unload", handleUnload);

    init();

    handleGetUserData();
    if (!("Notification" in window)) {
      console.log("Browser does not support desktop notification");
    } else {
      /*      if (userData?.role === "admin") { */
      Notification.requestPermission();
      /*  } */
    }
    return () => {
      // Clean up event listeners
      window.removeEventListener("unload", handleUnload);
    };

    async function handleUnload() {
      try {
        await channel.leave();
        await client.logout();

        window.location.reload();
      } catch (error) {
        console.error("Error during unload:", error);
      }
    }
  }, []);

  const handleNotify = () => {
    new Notification("User has joined!");
  };

  const handleGetUserData = async () => {
    store.dispatch(getUser());
  };

  const toggleCamera = async () => {
    let videoTrack = localStream
      .getTracks()
      .find((track) => track.kind === "video");
    console.log(localStream);
    if (videoTrack.enabled) {
      videoTrack.enabled = false;
      console.log(videoTrack);
      document.getElementById("camera-btn").style.backgroundColor =
        "rgb(255,80,80)";
    } else {
      videoTrack.enabled = true;
      /*   constraints.video = {
        width: { min: 640, ideal: 1920, max: 1920 },
        height: { min: 480, ideal: 1080, max: 1080 },
      }; */
      document.getElementById("camera-btn").style.backgroundColor = "#c39133";
    }
  };

  const toggleMic = async () => {
    let audioTrack = localStream
      .getTracks()
      .find((track) => track.kind === "audio");

    if (audioTrack.enabled) {
      audioTrack.enabled = false;
      document.getElementById("mic-btn").style.backgroundColor =
        "rgb(255,80,80)";
    } else {
      audioTrack.enabled = true;
      document.getElementById("mic-btn").style.backgroundColor = "#c39133";
    }
  };

  const handleLeave = async () => {
    // Stop and close the local media tracks
    localStream.getTracks().forEach((track) => {
      track.stop();
    });

    // Remove the tracks from the stream
    localStream.getTracks().forEach((track) => {
      localStream.removeTrack(track);
    });

    // Close the peer connection
    if (peerConnection) {
      peerConnection.close();
    }

    // Leave the Agora channel and logout
    await channel.leave();
    await client.logout();

    handleClose();
    setTimeout(() => {
      window.location.reload();
    }, 1500);
  };

  /*  const onCloseFunction = async () => {
       await channel.leave();
    await client.logout(); 
    console.log("LLLLLLLLLLLLLEEEEEEEEAAAAAAAAVVVVVVEEEEEEEEE");
  }; */
  window.addEventListener("beforeunload", handleLeave);

  return (
    <>
      <div id="videosClient">
        <video
          className="video-player"
          id="user-1"
          autoPlay
          playsInline
        ></video>
        <video
          className="video-player"
          id="user-2"
          autoPlay
          playsInline
        ></video>
      </div>

      <div id="controlsClient">
        <div
          className="control-container"
          id="camera-btn"
          onClick={toggleCamera}
        >
          <img src={camera} alt="camera" />
        </div>

        <div className="control-container" id="mic-btn" onClick={toggleMic}>
          <img src={mic} alt="mic" />
        </div>

        <div className="control-container" id="leave-btn" onClick={handleLeave}>
          <img src={phone} alt="phone" />
        </div>
      </div>
    </>
  );
};

export default VideoCall;
