import React from "react";
//mui
import Grid from "@mui/material/Grid";
//scss
import "./Dashboard.scss";
//react icons
import { AiFillHeart } from "react-icons/ai";
import {
  FaRoad,
  FaDollarSign,
  FaPercentage,
  FaSearch,
  FaLock,
} from "react-icons/fa";
import { ImCross } from "react-icons/im";
import StaticPartSecure from "./StaticPartSecure";
import language from "../../../language";
const StaticPart = () => {
  let languageSelectedEnglish =
    sessionStorage.getItem("language") === "english" ||
    !sessionStorage.getItem("language")
      ? true
      : false;

  return (
    <Grid container className="staticContainer">
      <Grid item xs={12} className="staticWrapper">
        <Grid item xs={10} className="SectionsWrapper">
          <Grid item xs={12} className="sectionHeading" textAlign="center">
            <h2 style={{ color: "#5D0139" }}>
              {languageSelectedEnglish
                ? "Why Book Bus with Non Redbus"
                : language.whyBookBusWithNonRedbus}
            </h2>
            <p className="subHeading">
              {languageSelectedEnglish
                ? " Book Bus Tickets Online. Save Time and Money!"
                : language.bookBusTicketsOnlineSaveTimeAndMoney}
            </p>
          </Grid>
          <Grid container className="prosWrapper">
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12} className="Card">
              <Grid item xs={2} className="icon" align="center">
                <FaDollarSign style={{ color: "5D0139", fontSize: "30px" }} />
              </Grid>
              <Grid item xs={10} className="content">
                <h5 className="heading">
                  {languageSelectedEnglish
                    ? " No Booking Charges"
                    : language.noBookingCharges}
                </h5>
                <p className="desc">
                  {languageSelectedEnglish
                    ? "No hidden charges, no payment fees, and free customer service.So you get the best deal every time!"
                    : language.noHiddenCharges}
                </p>
              </Grid>
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12} className="Card">
              <Grid item xs={2} className="icon" align="center">
                <FaSearch style={{ color: "5D0139", fontSize: "30px" }} />
              </Grid>
              <Grid item xs={10} className="content">
                <h5 className="heading">
                  {languageSelectedEnglish
                    ? "Quick and Easy Search"
                    : language.quickAndEasySearch}
                </h5>
                <p className="desc">
                  {languageSelectedEnglish
                    ? "We'll find you the best deals available from top bus companies for you to choose from, combining quality and economy."
                    : language.weWillFindYouTheBestDeals}
                </p>
              </Grid>
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12} className="Card">
              <Grid item xs={2} className="icon" align="center">
                <FaPercentage style={{ color: "5D0139", fontSize: "28px" }} />
              </Grid>
              <Grid item xs={10} className="content">
                <h5 className="heading">
                  {languageSelectedEnglish
                    ? "Cheapest Price"
                    : language.cheapestPrice}
                </h5>
                <p className="desc">
                  {languageSelectedEnglish
                    ? "Always get cheapest price with the best in the industry. Soyou get the best deal every time."
                    : language.alwaysGetCheapest}
                </p>
              </Grid>
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12} className="Card">
              <Grid item xs={2} className="icon" align="center">
                <FaRoad style={{ color: "5D0139", fontSize: "30px" }} />
              </Grid>
              <Grid item xs={10} className="content">
                <h5 className="heading">
                  {languageSelectedEnglish
                    ? "2 Lakh+ Routes"
                    : language.twoLakhRoutes}
                </h5>
                <p className="desc">
                  {languageSelectedEnglish
                    ? "Make your road journeys easier across world with 10000+Operators."
                    : language.makeYourRoadJourneys}
                </p>
              </Grid>
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12} className="Card">
              <Grid item xs={2} className="icon" align="center">
                <ImCross style={{ color: "5D0139", fontSize: "20px" }} />
              </Grid>
              <Grid item xs={10} className="content">
                <h5 className="heading">
                  {languageSelectedEnglish
                    ? "Easy Cancellation & Refunds"
                    : language.easyCancellationRefunds}
                </h5>
                <p className="desc">
                  {languageSelectedEnglish
                    ? " Get instant refund and get any booking fees waived off!"
                    : language.getInstantRefund}
                </p>
              </Grid>
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12} className="Card">
              <Grid item xs={2} className="icon" align="center">
                <AiFillHeart style={{ color: "5D0139", fontSize: "25px" }} />
              </Grid>
              <Grid item xs={10} className="content">
                <h5 className="heading">
                  {languageSelectedEnglish
                    ? "Every time, anywhere"
                    : language.everyTimeAnywhere}
                </h5>
                <p className="desc">
                  {languageSelectedEnglish
                    ? "Because your trip doesn't end with a ticket, we’re here foryou all the way"
                    : language.becauseYourTrip}
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <StaticPartSecure />
    </Grid>
  );
};

export default StaticPart;
