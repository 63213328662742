import React from "react";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <>
      <div className="privacyMain">
        <div className="privacyInner">
          <div className="privacyText">Privacy Policy</div>
          <div className="divider1"></div>
          <div className="detailText">
            This Privacy Policy describes how BusArabia ("we," "us," or "our")
            collects, uses, and shares information about you when you use our
            website and services.
          </div>
          <h4 className="heading">Information We Collect</h4>
          <ul className="enlisted">
            <li className="detailText">
              <b>Personal Information: </b>
              When you use our website to book bus tickets, we collect personal
              information such as your name, email address, phone number, and
              payment details.
            </li>
            <li className="detailText1">
              <b>Usage Information: </b>
              We may collect information about your interactions with our
              website, such as the pages you visit and the actions you take.
            </li>
            <li className="detailText1">
              <b>Device Information: </b>
              We may collect information about the device you use to access our
              website, including your IP address, browser type, and operating
              system.
            </li>
          </ul>

          <h4 className="heading">How We Use Your Information</h4>
          <ul className="enlisted">
            <li className="detailText">
              We use the information we collect to provide our services to you,
              including processing bookings and payments.
            </li>
            <li className="detailText1">
              We may use your information to communicate with you about your
              bookings, updates to our services, and promotional offers.
            </li>
            <li className="detailText1">
              We may use cookies and similar technologies to personalize your
              experience on our website and analyze how users interact with our
              site.
            </li>
          </ul>

          <h4 className="heading">Data Security</h4>
          <p className="detailText1">
            We take reasonable measures to protect the security of your
            information, but no method of transmission over the internet or
            electronic storage is 100% secure.
          </p>
          <br />

          <h4 className="heading">Updates to This Policy</h4>
          <p className="detailText1">
            We may update this Privacy Policy from time to time. We will notify
            you of any material changes by posting the updated policy on our
            website.
          </p>
          <br />

          <h4 className="heading">Contact Us</h4>
          <p className="detailText1">
            If you have any questions or concerns about our Privacy Policy,
            please contact us at customercare@busarabia.com.
          </p>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
