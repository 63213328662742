import React from 'react'
import Footer from '../../../mainLayout/Footer/Footer'
import Header from '../../../mainLayout/Header/Header'
import CardServices from "./../../Dashboard/CardServices"
const BusService = () => {
  return (
    <>
    {/* <Header/> */}

    <div className='topCardServices'>
    <CardServices title={"Book Bus Tickets"}/>
    </div>
    {/* <Footer/> */}
    
    </> 
  )
}

export default BusService