import React from "react";
import Grid from "@mui/material/Grid";
//icons
import AppleIcon from "@mui/icons-material/Apple";
import { IoLogoGooglePlaystore } from "react-icons/io5";
//styles
import "./Footer.scss";
import { Link } from "react-router-dom";
import language from "../../../language";
const Footer = () => {
  let languageSelectedEnglish =
    sessionStorage.getItem("language") === "english" ||
      !sessionStorage.getItem("language")
      ? true
      : false;
  return (
    <>
      {/* Desktop */}
      <Grid container className="FooterWrapper">
        <Grid
          container
          xl={9}
          lg={9}
          md={10}
          sm={11}
          xs={11}
          className="FooterSubWrap"
        >
          <Grid
            item
            xl={3}
            lg={3}
            md={3}
            sm={3}
            xs={12}
            className="LinksWrapper"
          >
            <p className="Header">
              {languageSelectedEnglish ? "Company" : language.company}
            </p>
            <Grid item className="LinksNav">
              <p className="LinksFooter">
                {languageSelectedEnglish ? "Home" : language.home}
              </p>
              <p className="LinksFooter">
                {languageSelectedEnglish ? "About" : language.about}
              </p>
              <p className="LinksFooter">
                {languageSelectedEnglish ? "Support" : language.support}
              </p>
              <p className="LinksFooter">
                {languageSelectedEnglish ? "Reviews" : language.reviews}
              </p>
            </Grid>
          </Grid>
          <Grid
            item
            xl={3}
            lg={3}
            md={3}
            sm={3}
            xs={12}
            className="LinksWrapper"
          >
            <p className="Header">
              {languageSelectedEnglish ? "About" : language.about}
            </p>
            <Grid item className="LinksNav">
              <p className="LinksFooter">
                {" "}
                {languageSelectedEnglish ? "About Us" : language.aboutUs}
              </p>
              <p className="LinksFooter">
                {" "}
                {languageSelectedEnglish ? "Find us" : language.findUs}
              </p>
              {/* <p className="LinksFooter">Find A Therapist</p> */}
            </Grid>
          </Grid>
          <Grid
            item
            xl={3}
            lg={3}
            md={3}
            sm={3}
            xs={12}
            className="LinksWrapper"
          >
            <p className="Header">
              {languageSelectedEnglish ? "My Account" : language.myAccount}
            </p>
            <Grid item className="LinksNav">
              <p className="LinksFooter">
                {languageSelectedEnglish ? "My Bookings" : language.myBookings}
              </p>
              <p className="LinksFooter">
                {languageSelectedEnglish
                  ? "Past Transactions"
                  : language.pastTransactions}
              </p>
            </Grid>
          </Grid>
          <Grid
            item
            xl={3}
            lg={3}
            md={3}
            sm={3}
            xs={12}
            className="LinksWrapper"
          >
            <p className="Header">
              {languageSelectedEnglish ? "Information" : language.Information}
            </p>
            <Grid item className="LinksNav">
              <Link
                to="/client/terms-and-policy"
                style={{ textDecoration: "none", color: "#0c2f54" }}
              >
                <p className="LinksFooter">
                  {languageSelectedEnglish
                    ? "Terms & Conditions"
                    : language.termsConditions}
                </p>
              </Link>
              <Link
                to="/client/privacy-policy"
                style={{ textDecoration: "none", color: "#0c2f54" }}
              >
                <p className="LinksFooter">
                  {languageSelectedEnglish
                    ? "Privacy Policy"
                    : language.privacyPolicy}
                </p>
              </Link>

              <Link
                to="/client/faqs"
                style={{ textDecoration: "none", color: "#0c2f54" }}
              >
                <p className="LinksFooter">
                  FAQs
                </p>
              </Link>
            </Grid>
          </Grid>
          {/* <Grid item className="LinksWrapper">
                        <p className="Header">Company</p>
                        <Grid item className="LinksNav">
                            <p className="LinksFooter">Home</p>
                            <p className="LinksFooter">Business</p>
                            <p className="LinksFooter">About</p>
                            <p className="LinksFooter">FAQ</p>
                            <p className="LinksFooter">Reviews</p>
                        </Grid>
                    </Grid> */}
        </Grid>
        <Grid align="center" item xs={12} className="horizontalLineWrapper">
          <div className="HorizontalLine" />
        </Grid>
        <Grid container xs={12} className="buttonContainer">
          <Grid item xs={12} className="label" align="center">
            <p className="footerLabel">© 2022 Bus Arabia</p>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Footer;
