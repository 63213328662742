import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import { withRouter } from "react-router";
//scss
import "./Dashboard.scss";
//component
import StaticPart from "./StaticPart";
import SearchForm from "./SearchForm";
import Carousal from "./Carousal";
import TravelServices from "./TravelServices";
import language from "../../../language";
//mui
import Grid from "@mui/material/Grid";

//assets
import busPromo from "../../assets/sadaat.png";

const Dashboard = (props) => {
  const [date, setDate] = React.useState(new Date());
  const [seatNo, setSeatNo] = React.useState(1);
  const [selected, setSelected] = React.useState("Bus Ticket");

  const handleDate = (newDate) => {
    setDate(newDate);
  };

  const handleChangeSeat = (event) => {
    setSeatNo(event.target.value);
  };
  let languageSelectedEnglish =
    sessionStorage.getItem("language") === "english" ||
    !sessionStorage.getItem("language")
      ? true
      : false;
  return (
    <>
      <Grid container className="LandingWrapper">
        <Grid container xs={12} className="form-wrapper">
          <Grid container xs={10}>
            <Grid item xs={12}>
              <h1 className="title-home">
                {languageSelectedEnglish
                  ? "Explore Yemen!"
                  : language.exploreYemen}
              </h1>
              <p className="subtitle-home">
                {languageSelectedEnglish
                  ? "Visit amazing places with great views and mountains"
                  : language.visitAmazingPlaces}
              </p>
            </Grid>
            <Grid container xs={12} className="categories">
              <Grid
                item
                className={`${
                  selected == "Bus Ticket" ? "selected" : ""
                } category-item`}
                onClick={() => setSelected("Bus Ticket")}
              >
                <p>
                  {languageSelectedEnglish ? "Bus Ticket" : language.busTicket}
                </p>
              </Grid>
              <Grid
                item
                className={`${
                  selected == "Visa Services" ? "selected" : ""
                } category-item`}
                onClick={() => setSelected("Visa Services")}
              >
                <p>
                  {languageSelectedEnglish
                    ? "Visa Services"
                    : language.visaServices}
                </p>
              </Grid>
              <Grid
                item
                className={`${
                  selected == "Cargo Service" ? "selected" : ""
                } category-item`}
                onClick={() => setSelected("Cargo Service")}
              >
                <p>
                  {languageSelectedEnglish
                    ? "Cargo Service"
                    : language.cargoServices}
                </p>
              </Grid>
              <Grid
                item
                className={`${
                  selected == "Hajj" ? "selected" : ""
                } category-item`}
                onClick={() => setSelected("Hajj")}
              >
                <p>{languageSelectedEnglish ? "Hajj" : language.hajj}</p>
              </Grid>
              <Grid
                item
                className={`${
                  selected == "Umrah" ? "selected" : ""
                } category-item`}
                onClick={() => setSelected("Umrah")}
              >
                <p>{languageSelectedEnglish ? "Umrah" : language.umrah}</p>
              </Grid>
              <Grid
                item
                className={`${
                  selected == "Tourism" ? "selected" : ""
                } category-item`}
                onClick={() => setSelected("Tourism")}
              >
                <p>{languageSelectedEnglish ? "Tourism" : language.tourism}</p>
              </Grid>
              <Grid
                item
                className={`${
                  selected == "Bus Rental" ? "selected" : ""
                } category-item`}
                onClick={() => setSelected("Bus Rental")}
              >
                <p>
                  {languageSelectedEnglish ? "Bus Rental" : language.busRental}
                </p>
              </Grid>
            </Grid>
            <Grid container xs={12} className="BookingContainer">
              <Grid item className="inputCardsWrapper">
                <Grid container className="CardsContainer">
                  <Grid
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="placeWrapper"
                  >
                    <p className="heading">
                      {selected == "Bus Ticket"
                        ? languageSelectedEnglish
                          ? "Book Your Bus Ticket"
                          : language.bookYourBusTicket
                        : null}
                      {selected == "Visa Services"
                        ? languageSelectedEnglish
                          ? "Get your Visa to Saudi Arabia"
                          : language.getYourVisaToSaudiArabia
                        : null}
                      {selected == "Cargo Service"
                        ? languageSelectedEnglish
                          ? "Get your Cargo Service to Saudi Arabia"
                          : language.getYourCargoServiceToSaudiArabia
                        : null}
                      {selected == "Hajj"
                        ? languageSelectedEnglish
                          ? "Get your Hajj Service to Saudi Arabia"
                          : language.getYourHajjServiceToSaudiArabia
                        : null}
                      {selected == "Umrah"
                        ? languageSelectedEnglish
                          ? "Get your Umrah Service to Saudi Arabia"
                          : language.getYourUmrahServiceToSaudiArabia
                        : null}
                      {selected == "Tourism"
                        ? languageSelectedEnglish
                          ? "Get your Tourism Service to Saudi Arabia"
                          : language.getYourTourismServiceToSaudiArabia
                        : null}
                      {selected == "Bus Rental"
                        ? languageSelectedEnglish
                          ? "Bus Rental"
                          : language.busRental
                        : null}
                    </p>
                    {selected == "Bus Ticket" ? <SearchForm /> : null}
                    {selected !== "Bus Ticket" ? (
                      <TravelServices service={selected} />
                    ) : null}
                    {/*   {selected == 'Cargo Service' ? <TravelServices service="Cargo Service" /> : null}
                    {selected == 'Hajj' ? <TravelServices service="Hajj" /> : null}
                    {selected == 'Umrah' ? <TravelServices service="Umrah" /> : null}
                    {selected == 'Tourism' ? <TravelServices service="Tourism" /> : null}
                    {selected == 'Bus Rental' ? <TravelServices service="Bus Rental" /> : null} */}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} align="center" marginTop="72px">
                <h2 className="free-ticket">
                  {languageSelectedEnglish
                    ? "Grab your Free Tickets!!"
                    : language.grabYourFreeTickets}
                </h2>
                <p className="free-details">
                  {languageSelectedEnglish
                    ? "Every 10th ticket issued by our website is free"
                    : language.every10thTicket}
                  ,{" "}
                  <span style={{ color: "#C79638" }}>
                    {languageSelectedEnglish
                      ? "Click here"
                      : language.clickHere}
                  </span>{" "}
                  {languageSelectedEnglish
                    ? "to see the daily lucky winners from the past"
                    : language.toSeeTheDailyLuckyWinners}
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Carousal />
        <StaticPart />
      </Grid>
    </>
  );
};

export default Dashboard;
