import React, { useState } from "react";
// React Bootstrap
import Form from "react-bootstrap/Form";
import { addRequest } from "../../../actions/request";
import { connect } from "react-redux";
import propTypes from "prop-types";
import store from "../../../store";
import language from "../../../language";
const TravelServices = (service) => {
  const [values, setValues] = useState({
    category: service.service,
    name: "",
    email: "",
    mobnumber: "",
    message: "",
  });
  let languageSelectedEnglish =
    sessionStorage.getItem("language") === "english" ||
    !sessionStorage.getItem("language")
      ? true
      : false;

  const handleChange = (e) => {
    const Value = e.target.value;

    setValues({ ...values, [e.target.name]: Value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    let data = {
      category: service.service,
      name: values.name,
      email: values.email,
      mobnumber: values.mobnumber,
      message: values.message,
    };
    /*    data.append("category", service.service);
    data.append("name", values.name);
    data.append("email", values.email);
    data.append("mobnumber", values.mobnumber);
    data.append("message", values.message); */

    store.dispatch(addRequest(data));
    setValues({
      category: "",
      name: "",
      email: "",
      mobnumber: "",
      message: "",
    });
  };

  return (
    <div style={{ marginTop: "20px" }}>
      <Form
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label className="search-form-title">
            {languageSelectedEnglish ? "Name" : language.name}
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={
              languageSelectedEnglish ? "Your full name" : language.yourFullName
            }
            name="name"
            value={values.name}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label className="search-form-title">
            {languageSelectedEnglish ? "Email" : language.email}
          </Form.Label>
          <Form.Control
            type="email"
            placeholder={
              languageSelectedEnglish ? "Email Address" : language.emailAddress
            }
            name="email"
            value={values.email}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label className="search-form-title">
            {languageSelectedEnglish ? "Phone Number" : language.phoneNumber}
          </Form.Label>
          <Form.Control
            type="number"
            placeholder={
              languageSelectedEnglish ? "Phone Number" : language.phoneNumber
            }
            name="mobnumber"
            value={values.mobnumber}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label className="search-form-title">
            {languageSelectedEnglish ? "Your query" : language.yourQuery}
          </Form.Label>
          <Form.Control
            as="textarea"
            placeholder={
              languageSelectedEnglish
                ? `Enter your requirement for ${service.service}`
                : language.enterYourRequirement
            }
            name="message"
            value={values.message}
            onChange={handleChange}
          />
        </Form.Group>
        <button className="senbtn" type="submit">
          {languageSelectedEnglish ? "Send" : language.send}
        </button>
      </Form>
    </div>
  );
};
TravelServices.propTypes = {
  addRequest: propTypes.func.isRequired,
};

export default connect(null, { addRequest })(TravelServices);
