import React, { useContext, Suspense, useEffect, lazy } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import "./adminsOperator.css";
import Main from "./Main";
import routes from "../routes";
// import routesClient from "../routes/routesClient";
import Header from "../components/header/Header";
import Sidebar from "../components/sidebar/Sidebar";
import { SidebarContext } from "../context/SidebarContext";
import ThemeSuspense from "../components/theme/ThemeSuspense";
import { BiPhoneCall } from "react-icons/bi";

//const Page404 = lazy(() => import("../pages/404"));
const Layout = () => {
  const { isSidebarOpen, closeSidebar } = useContext(SidebarContext);

  let location = useLocation();

  const params = window.location.href.split("/");

  useEffect(() => {
    closeSidebar();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  console.log("params", params);

  return (
    <>
      {params[3] == "user" ? (
        <Suspense fallback={<ThemeSuspense />}>
          <Switch>
            {routes.map((route, i) => {
              return route.component ? (
                <Route
                  key={i}
                  exact={true}
                  path={`${route.path}`}
                  render={(props) => <route.component {...props} />}
                />
              ) : null;
            })}
            <Redirect exact from="/" to="/admin/dashboard" />
            {/*<Route component={Page404} />*/}
          </Switch>
        </Suspense>
      ) : (
        <>
          <div
            className={`flex h-screen bg-gray-50 ${isSidebarOpen && "overflow-hidden"
              }`}
          >
            <Sidebar />
            <div className="flex flex-col flex-1 max-w-full">
              <Header />

              <Main>
                <Suspense fallback={<ThemeSuspense />}>
                  <Switch>
                    {routes.map((route, i) => {
                      return route.component ? (
                        <Route
                          key={i}
                          exact={true}
                          path={`${route.path}`}
                          render={(props) => <route.component {...props} />}
                        />
                      ) : null;
                    })}
                    <Redirect exact from="/" to="/admin/dashboard" />
                    {/*<Route component={Page404} />*/}
                  </Switch>
                </Suspense>
                {/* <button
                  className="voice-call-demo"
                  style={{
                    bottom: "20px",
                    right: "20px",
                    top: "auto",
                  }}
                  onClick={() => window.open("/admin-support-call", "_blank")}
                >
                  <BiPhoneCall color="#fff" fontSize="50px" />
                </button> */}
              </Main>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Layout;
