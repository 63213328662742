import React, { useState, useEffect } from "react";
import "./FAQs.scss";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import { FaPlus } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";

const FAQWeb = () => {
  const [expandedAccordion, setExpandedAccordion] = useState(null);

  const toggleAccordion = (accordionId) => {
    setExpandedAccordion(
      expandedAccordion === accordionId ? null : accordionId
    );
  };

  return (
    <>
      <div className="faqMain">
        <div className="faqText">Frequently asked questions</div>
        <div className="accoprdionOuter">
          <Accordion
            expanded={expandedAccordion === 1}
            onChange={() => toggleAccordion(1)}
          >
            <AccordionSummary
              expandIcon={
                expandedAccordion === 1 ? (
                  <FaMinus className="minusColor" />
                ) : (
                  <FaPlus className="minusColor" />
                )
              }
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <div className="faqHeading">
                How do I book a bus ticket on BusArabia?
              </div>
            </AccordionSummary>
            <AccordionDetails>
              To book a bus ticket on BusArabia, simply visit our website,
              select your desired destination, travel dates, and preferred bus
              route. Then, follow the prompts to complete the booking process.
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expandedAccordion === 2}
            onChange={() => toggleAccordion(2)}
          >
            <AccordionSummary
              expandIcon={
                expandedAccordion === 2 ? (
                  <FaMinus className="minusColor" />
                ) : (
                  <FaPlus className="minusColor" />
                )
              }
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <div className="faqHeading">
                Can I book a bus ticket for someone else?
              </div>
            </AccordionSummary>
            <AccordionDetails>
              Yes, you can book a bus ticket for someone else. During the
              booking process, you'll have the option to enter the passenger's
              details, including their name and contact information.
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expandedAccordion === 3}
            onChange={() => toggleAccordion(3)}
          >
            <AccordionSummary
              expandIcon={
                expandedAccordion === 3 ? (
                  <FaMinus className="minusColor" />
                ) : (
                  <FaPlus className="minusColor" />
                )
              }
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <div className="faqHeading">
                What payment methods do you accept?
              </div>
            </AccordionSummary>
            <AccordionDetails>
              We accept various payment methods, including credit/debit cards,
              online banking, and other electronic payment options.
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expandedAccordion === 4}
            onChange={() => toggleAccordion(4)}
          >
            <AccordionSummary
              expandIcon={
                expandedAccordion === 4 ? (
                  <FaMinus className="minusColor" />
                ) : (
                  <FaPlus className="minusColor" />
                )
              }
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <div className="faqHeading">
                Is it safe to make online payments on BusArabia?
              </div>
            </AccordionSummary>
            <AccordionDetails>
              Yes, it is safe to make online payments on BusArabia. We use
              industry-standard encryption and security measures to protect your
              personal and financial information.
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expandedAccordion === 5}
            onChange={() => toggleAccordion(5)}
          >
            <AccordionSummary
              expandIcon={
                expandedAccordion === 5 ? (
                  <FaMinus className="minusColor" />
                ) : (
                  <FaPlus className="minusColor" />
                )
              }
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <div className="faqHeading">
                Can I cancel or change my bus ticket booking?
              </div>
            </AccordionSummary>
            <AccordionDetails>
              Yes, you can cancel or change your bus ticket booking, subject to
              our cancellation and change policies. Please refer to our terms
              and conditions for more information.
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expandedAccordion === 6}
            onChange={() => toggleAccordion(6)}
          >
            <AccordionSummary
              expandIcon={
                expandedAccordion === 6 ? (
                  <FaMinus className="minusColor" />
                ) : (
                  <FaPlus className="minusColor" />
                )
              }
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <div className="faqHeading">
                How do I get my bus ticket after booking online?
              </div>
            </AccordionSummary>
            <AccordionDetails>
              After completing your booking online, you will receive a
              confirmation email with your bus ticket details. You can either
              print the ticket or show it on your mobile device when boarding
              the bus.
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expandedAccordion === 6}
            onChange={() => toggleAccordion(6)}
          >
            <AccordionSummary
              expandIcon={
                expandedAccordion === 6 ? (
                  <FaMinus className="minusColor" />
                ) : (
                  <FaPlus className="minusColor" />
                )
              }
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <div className="faqHeading">
                Are there any additional fees or charges?
              </div>
            </AccordionSummary>
            <AccordionDetails>
              Our pricing includes all applicable taxes and fees. However, there
              may be additional charges for optional services or special
              requests.
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expandedAccordion === 6}
            onChange={() => toggleAccordion(6)}
          >
            <AccordionSummary
              expandIcon={
                expandedAccordion === 6 ? (
                  <FaMinus className="minusColor" />
                ) : (
                  <FaPlus className="minusColor" />
                )
              }
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <div className="faqHeading">
                What types of buses are available for booking?
              </div>
            </AccordionSummary>
            <AccordionDetails>
              We offer a variety of bus types to suit different preferences and
              budgets, including standard, deluxe, and sleeper buses.
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expandedAccordion === 6}
            onChange={() => toggleAccordion(6)}
          >
            <AccordionSummary
              expandIcon={
                expandedAccordion === 6 ? (
                  <FaMinus className="minusColor" />
                ) : (
                  <FaPlus className="minusColor" />
                )
              }
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <div className="faqHeading">
                Do you offer discounts for group bookings?
              </div>
            </AccordionSummary>
            <AccordionDetails>
              Yes, we offer discounts for group bookings. Please contact our
              customer service team for more information on group rates.
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expandedAccordion === 6}
            onChange={() => toggleAccordion(6)}
          >
            <AccordionSummary
              expandIcon={
                expandedAccordion === 6 ? (
                  <FaMinus className="minusColor" />
                ) : (
                  <FaPlus className="minusColor" />
                )
              }
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <div className="faqHeading">
                Can I select my seat when booking a bus ticket?
              </div>
            </AccordionSummary>
            <AccordionDetails>
              Yes, you can select your seat when booking a bus ticket, subject
              to availability.
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </>
  );
};

export default FAQWeb;
