const language = {
  exploreYemen: "",
  visitAmazingPlaces: "",
  busTicket: "",
  visaServices: "",
  cargoServices: "",
  hajj: "",
  umrah: "",
  tourism: "",
  busRental: "",
  bookYourBusTicket: "",
  getYourVisaToSaudiArabia: "",
  getYourCargoServiceToSaudiArabia: "",
  getYourHajjServiceToSaudiArabia: "",
  getYourUmrahServiceToSaudiArabia: "",
  getYourTourismServiceToSaudiArabia: "",
  busRental: "",
  from: "",
  to: "",
  departureDate: "",
  returnDate: "",
  passengers: "",
  notSelected: "",
  infants: "",
  children: "",
  adults: "",
  searchBusTrips: "",
  name: "",
  yourFullName: "",
  email: "",
  emailAddress: "",
  phoneNumber: "",
  yourQuery: "",
  enterYourRequirement: "",
  send: "",
  grabYourFreeTickets: "",
  every10thTicket: "",
  clickHere: "",
  toSeeTheDailyLuckyWinners: "",
  whyBookBusWithNonRedbus: "",
  bookBusTicketsOnlineSaveTimeAndMoney: "",
  noBookingCharges: "",
  noHiddenCharges: "",
  quickAndEasySearch: "",
  weWillFindYouTheBestDeals: "",
  alwaysGetCheapest: "",
  twoLakhRoutes: "",
  makeYourRoadJourneys: "",
  easyCancellationRefunds: "",
  getInstantRefund: "",
  everyTimeAnywhere: "",
  becauseYourTrip: "",
  hundredSecurePayments: "",
  movingYourCardDetails: "",
  trustPay: "",
  hundredpaymentProtection: "",
  referEarn: "",
  inviteAFriend: "",
  twentySevenSupport: "",
  weHereToHelp: "",
  chooseYourLanguage: "",
  editProfile: "",
  logout: "",
  login: "",
  welcome: "",
  signUpForAnAccount: "",
  email: "",
  password: "",
  confirmPassword: "",
  signUp: "",
  or: "",
  signUpWithGoogle: "",
  signUpWithFacebook: "",
  alreadyAMember: "",
  goSignIn: "",
  areYouATransportCompany: "",
  signIn: "",
  signInToYourAccount: "",
  forgotYourPassword: "",
  notAMemberYet: "",
  goSignUp: "",
  weGotYouCovered: "",
  company: "",
  home: "",
  about: "",
  support: "",
  reviews: "",
  aboutUs: "",
  findUs: "",
  myAccount: "",
  myBookings: "",
  pastTransactions: "",
  termsConditions: "",
  search: "",
  selectADate: "",
  filter: "",
  busType: "",
  executiveClass: "",
  businessClass: "",
  operators: "",
  departure: "",
  duration: "",
  arrival: "",
  busPlateNumber: "",
  price: "",
  availableSeats: "",
  theBaggage: "",
  viewBusDetail: "",
  viewAndSelectSeat: "",
  refunds: "",
  emptySeat: "",
  reservedSeat: "",
  selectedSeat: "",
  pleaseSelectYourSeatsBelow: "",
  numberOfSeats: "",
  bookingDetails: "",
  totalFare: "",
  next: "",
  hoursBeforeDeparture: "",
  refundPercentage: "",
  before: "",
  above: "",
  thePenaltyIsSubject: "",
  theChargesArePerSeat: "",
  partialCancellationIsNotAllowed: "",
  inCaseOfNoShowOrTicket: "",
  busBookingDetails: "",
  aboutBus: "",
  theMainTerminalIsLocatedAt: "",
  busData: "",
  totalSeats: "",
  baggage: "",
  allowed: "",
  busFacilities: "",
  rateUs: "",
  passengerDetail: "",
  passenger: "",
  information: "",
  firstName: "",
  middleName: "",
  lastName: "",
  enterEmail: "",
  gender: "",
  male: "",
  female: "",
  passportNumber: "",
  selectNationality: "",
  mobileNumber: "",
  uploadCopyOfThePassport: "",
  submitAndProceed: "",
  checkout: "",
  cardInformation: "",
  nameAsDisplayedOnCard: "",
  expiry: "",
  address: "",
  postalCode: "",
  phoneNumber: "",
  details: "",
  businessClass: "",
  iHaveDiscountCode: "",
  enterCouponCode: "",
  subtotal: "",
  tax: "",
  total: "",
  proceedToPay: "",
  travelItinerary: "",
  status: "",
  date: "",
  seatNumber: "",
  paymentMethod: "",
  credit: "",
  fare: "",
  discount: "",
  totalAmountPaid: "",
  printOrSaveTravelItinerary: "",
  admin: "",
  youCanLoginHere: "",
  adminPanel: "",
  dashboard: "",
  editProfile: "",
  buses: "",
  trips: "",
  customers: "",
  bookings: "",
  coupons: "",
  requests: "",
  busStations: "",
  searchByBusNumberPlate: "",
  searchByCategory: "",
  addBus: "",
  busNumberPlate: "",
  category: "",
  seats: "",
  facilites: "",
  seatSelection: "",
  actions: "",
  addYourBusAndNecessaryInformation: "",
  busImage: "",
  operatorID: "",
  selectIcons: "",
  busSeats: "",
  numberOfBaggagesAllowed: "",
  cancel: "",
  searchByOperator: "",
  addTrip: "",
  exclusiveTrips: "",
  regularTrips: "",
  tripID: "",
  departureStationName: "",
  arrivalStationName: "",
  operatorName: "",
  tripType: "",
  transit: "",
  selectTransitCities: "",
  repetition: "",
  busPrice: "",
  departureCity: "",
  arrivalCity: "",
  arrivalCityStationName: "",
  departureCityStationName: "",
  busDeparture: "",
  busArrival: "",
  customers: "",
  searchByCivilianID: "",
  searchByBookingID: "",
  searchByName: "",
  id: "",
  joiningDate: "",
  creditScore: "",
  searchByID: "",
  searchByEmail: "",
  verification: "",
  verified: "",
  notVerified: "",
  clickToVerify: "",
  addBooking: "",
  pastBookings: "",
  upcomingBookings: "",
  bookingID: "",
  departureTime: "",
  arrivalTime: "",
  paymentMethod: "",
  bookingAmount: "",
  isPaymentDone: "",
  bookedBy: "",
  creditDebit: "",
  done: "",
  notYet: "",
  updateBooking: "",
  updateYourBookingAndNecessaryInformation: "",
  addYourBookingAndNecessaryInformation: "",
  selectATrip: "",
  numberOfPassengers: "",
  selectSeats: "",
  additionalDetails: "",
  searchByCouponCodeName: "",
  addCoupon: "",
  couponID: "",
  startDate: "",
  endDate: "",
  campaignsName: "",
  code: "",
  percentage: "",
  updateCoupon: "",
  updatedYourCouponAndNecessaryInformation: "",
  addYourCouponAndNecessaryInformation: "",
  couponBannerImage: "",
  couponTitle: "",
  campaignCode: "",
  couponValidityTime: "",
  couponValidationEndTime: "",
  discountPercentage: "",
  requestsForServices: "",
  requestID: "",
  message: "",
  closed: "",
  open: "",
  companyLogo: "",
  username: "",
  contactnumber: "",
  updateProfile: "",
  loginWithFacebook: "",
  loginWithGoogle: "",
  createAccount: "",
  exclusive: "",
  regular: "",
  dateOfBirth: "",
  nationality: "",
  update: "",
  busStationName: "",
  addBusStation: "",
  picture: "",
  addLocation: "",
  addMoreLocations: "",
  updateBusStations: "",
  companyName: "",
  companyRepresentativeName: "",
  companyHeadOfficeAddress: "",
  companyHeadOfficePhoneNumber: "",
  companyHeadOfficeLocation: "",
  chooseLocation: "",
  submit: "",
  location: "",
  changeLocation: "",
};

export default language;
