import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { BiLogoGoogle, BiLogoFacebookCircle } from "react-icons/bi";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";

import { useHistory } from "react-router-dom";
import { login } from "../../../Redux/userReducer";
import language from "../../../language";
const Signin = ({
  status,
  setStatusToSignup,

  setStatusToForgot,
}) => {
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  let languageSelectedEnglish =
    sessionStorage.getItem("language") === "english" ||
    !sessionStorage.getItem("language")
      ? true
      : false;

  const dispatch = useDispatch();
  const history = useHistory();
  const regexp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const handleInput = (e) => {
    const Value = e.target.value;
    setUser({ ...user, [e.target.name]: Value });
  };
  console.log(user);
  const loginClicked = async () => {
    let response = false;
    /*     if (!user.email || !user.password) {
      setValidClick(true);
    } */
    if (user.email && user.password && regexp.test(user.email)) {
      response = await dispatch(login(user));
      // console.log("responseLogin", response.payload.success);
      console.log("responseLogin", response?.payload?.user?.role);
    }

    // console.log("isLogin",isLogin?.payload?.user?.role)

    if (response?.payload?.user?.role === "operator") {
      Swal.fire({
        icon: "correct",
        title: "Successfully Logged in",
        text: "",
      }).then((data) => {
        if (data) {
          // history.push("/operator/dashboard")
          window.location.assign("/operator/dashboard");
        }
      });
    }
    if (response?.payload?.user?.role === "customer") {
      Swal.fire({
        icon: "correct",
        title: "Successfully Logged in",
        text: "",
      }).then((data) => {
        if (data) {
          // history.push("/client/bus-service")
          window.location.assign("/client/bus-listing2/home");
        }
      });
    }
  };

  return (
    <div className="width-wrap">
      <Grid container className="signup-wrapper">
        <Grid item xs={12} align="center" className="">
          <h3 className="signup-welcome">
            {languageSelectedEnglish ? "Welcome!" : language.welcome}
          </h3>
          <p className="signup-pre">
            {languageSelectedEnglish
              ? "Sign in to your account!"
              : language.signInToYourAccount}
          </p>
          <div className="signup-form">
            <input
              name="email"
              type="email"
              value={user.email}
              placeholder={languageSelectedEnglish ? "Email" : language.email}
              onChange={handleInput}
              required
            />
            <input
              name="password"
              type="password"
              value={user.password}
              placeholder={
                languageSelectedEnglish ? "Password" : language.password
              }
              onChange={handleInput}
              required
            />
          </div>
          <Grid item xs={12} align="end">
            <p className="forgot-password" onClick={setStatusToForgot}>
              {languageSelectedEnglish
                ? " Forgot your password"
                : language.forgotYourPassword}
            </p>
          </Grid>
          <button className="button-signup" onClick={loginClicked}>
            {languageSelectedEnglish ? "Sign in" : language.signIn}
          </button>
          <p className="or-text">
            {" "}
            {languageSelectedEnglish ? "OR" : language.or}
          </p>
          <button className="button-signup-google">
            <div className="">
              <BiLogoGoogle
                style={{
                  color: "#4889F4",
                  fontSize: "20px",
                  marginRight: "5px",
                }}
              />
            </div>
            <div>
              {languageSelectedEnglish
                ? "Sign in with Google"
                : language.signUpWithGoogle}
            </div>
          </button>
          <button className="button-signup-facebook">
            <div className="">
              <BiLogoFacebookCircle
                style={{ color: "#fff", fontSize: "20px", marginRight: "5px" }}
              />
            </div>
            <div>
              {languageSelectedEnglish
                ? "Sign in with Facebook"
                : language.signUpWithFacebook}
            </div>
          </button>
          <Grid item xs={12} align="right">
            <p className="signin-pre">
              {languageSelectedEnglish
                ? "Not a member yet?"
                : language.notAMemberYet}{" "}
              <span
                onClick={setStatusToSignup}
                style={{
                  cursor: "pointer",
                }}
              >
                {languageSelectedEnglish ? "Go sign up" : language.goSignUp}
              </span>
            </p>
            <p className="signin-pre">
              {languageSelectedEnglish
                ? "Are you a Transport Company? Go"
                : language.areYouATransportCompany}
              <span
                onClick={() => history.push("/operator/login")}
                style={{
                  cursor: "pointer",
                }}
              >
                {languageSelectedEnglish ? " sign in " : language.signIn}
              </span>
              {languageSelectedEnglish ? "or" : language.or}
              <span
                onClick={() => history.push("/operator/signup")}
                style={{
                  cursor: "pointer",
                }}
              >
                {languageSelectedEnglish ? "Sign up" : language.signUp}
              </span>
            </p>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Signin;
