import React, { useState, useEffect } from "react";
// Components
import Header2 from "../../mainLayout/Header/Header2";
import HeaderComponent from "../../mainLayout/Header/HeaderComponent";
import StaticPart from "../Dashboard/StaticPart";
import CardServices from "./../Dashboard/CardServices";
// React Bootstrap
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
// React icons
import { BiPhoneCall } from "react-icons/bi";
import AgoraUIKit from "agora-react-uikit";
/* global zugora */
import VideoCall from "../VideoCall/VideoCall";
const BusListing2 = () => {
  const [show, setShow] = useState(false);
  /*   const [videoCall, setVideoCall] = useState(true);

  const rtcProps = {
    appId: "8e8bbdbece924fc79a460ef46dced1fa",
    channel: "test",
    token: null, // enter your channel token as a string
  };

  const callbacks = {
    EndCall: () => setVideoCall(false),
  }; */
  // useEffect(() => {

  // Dynamically create a script element

  // }, [])

  const handleVoiceModal = () => {
    handleShow();
  };
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  return (
    <>
      {/* <Header2/> */}
      <div className="topCardServices">
        {/* <HeaderComponent title={"Book Bus Tickets"}/> */}
        <CardServices />
      </div>
      {/* Voice call Button */}
      {/* <button className="voice-call-demo" onClick={handleShow}>
        <BiPhoneCall color="#fff" fontSize="50px" />
      </button> */}

      <Modal
        show={show}
        onHide={handleClose}
        className="videoModal"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>BusArabia Support</Modal.Header>
        <Modal.Body>
          <VideoCall handleClose={handleClose} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BusListing2;
