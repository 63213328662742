export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const TRIPS_LOADED = "TRIPS_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const USER_LOADED = "USER_LOADED";
export const ALL_BUSES_LOADED = "ALL_BUSES_LOADED";
export const ALL_BUSES_FAIL = "ALL_BUSES_FAIL";
export const ADD_BUS_SUCCESS = "ADD_BUS_SUCCESS";
export const ADD_BUS_FAIL = "ADD_BUS_FAIL";
export const ALL_OPERATORS_LOADED = "ALL_OPERATORS_LOADED";
export const ALL_OPERATORS_FAIL = "ALL_OPERATORS_FAIL";
export const UPDATE_BUS_SUCCESS = "UPDATE_BUS_SUCCESS";
export const UPDATE_BUS_FAIL = "UPDATE_BUS_FAIL";
export const EXCLUSIVE_TRIPS_LOADED = "EXCLUSIVE_TRIPS_LOADED";
export const EXCLUSIVE_TRIPS_ERROR = "EXCLUSIVE_TRIPS_ERROR";
export const REGULAR_TRIPS_LOADED = "REGULAR_TRIPS_LOADED";
export const REGULAR_TRIPS_ERROR = "EXCLUSIVE_TRIPS_ERROR";
export const ALL_CUSTOMER_ERROR = "ALL_CUSTOMER_ERROR";
export const ALL_CUSTOMERS_LOADED = "ALL_CUSTOMERS_LOADED";
export const PAST_BOOKINGS_LOADED = "PAST_BOOKINGS_LOADED";
export const UPCOMING_BOOKINGS_LOADED = "UPCOMING_BOOKINGS_LOADED";
export const BOOKINGS_ERROR = "BOOKINGS_ERROR";
export const ADD_TRIP_SUCCESS = "ADD_TRIP_SUCCESS";
export const ADD_TRIP_FAIL = "ADD_TRIP_FAIL";
export const UPDATE_TRIP_SUCCESS = "UPDATE_TRIP_SUCCESS";
export const UPDATE_TRIP_FAIL = "UPDATE_TRIP_FAIL";
export const DELETE_TRIP_SUCCESS = "DELETE_TRIP_SUCCESS";
export const DELETE_TRIP_FAIL = "DELETE_TRIP_FAIL";
export const VERIFY_OPERATOR_FAIL = "VERIFY_OPERATOR_FAIL";
export const VERIFY_OPERATOR_SUCCESS = "VERIFY_OPERATOR_SUCCESS";
export const DELETE_OPERATOR_SUCCESS = "DELETE_OPERATOR_SUCCESS";
export const DELETE_OPERATOR_FAIL = "DELETE_OPERATOR_FAIL";
export const OPERATOR_LOADED = "OEPRATOR_LOADED";
export const OPERATOR_FAIL = "OPERATOR_FAIL";
export const ALL_TRIPS_LOADED = "ALL_TRIPS_LOADED";
export const TRIP_LOADED = "TRIP_LOADED";
export const ADD_BOOKING_FAIL = "ADD_BOOKING_FAIL";
export const ADD_BOOKING_SUCCESS = "ADD_BOOKING_SUCCESS";
// Operator
export const OPERATOR_LOGIN_FAIL = "OPERATOR_LOGIN_FAIL";
export const OPERATOR_LOGIN_SUCCESS = "OPERATOR_LOGIN_SUCCESS";
export const OPERATOR_SIGNUP_SUCCESS = "OPERATOR_SIGNUP_SUCCESS";
export const OPERATOR_SIGNUP_FAIL = "OPERATOR_SIGNUP_FAIL";
//Request
export const REQUEST_LOADED = "REQUEST_LOADED";
export const ADD_REQUEST_SUCCESS = "ADD_REQUEST_SUCCESS";
export const ADD_REQUEST_FAIL = "ADD_REQUEST_FAIL";
export const UPDATE_REQUEST_SUCCESS = "UPDATE_REQUEST_SUCCESS";
export const UPDATE_REQUEST_FAIL = "UPDATE_REQUEST_FAIL";
export const DELETE_REQUEST_SUCCESS = "DELETE_REQUEST_SUCCESS";
export const DELETE_REQUEST_FAIL = "DELETE_REQUEST_FAIL";
export const ALL_REQUESTS_LOADED = "ALL_REQUESTS_LOADED";
