import {
  FiGrid,
  FiShoppingBag,
  FiUsers,
  FiUser,
  FiCompass,
  FiGift,
  FiList,
  FiSettings,
} from "react-icons/fi";
import { BsBusFront, BsFillChatDotsFill } from "react-icons/bs";
import language from "../../language";
import { BiTrip } from "react-icons/bi";
import { AiOutlineUser } from "react-icons/ai";
import { FaFileInvoice } from "react-icons/fa";
import { MdRequestPage } from "react-icons/md";
/**
 * ⚠ These are used just to render the Sidebar!
 * You can include any link here, local or external.
 *
 * If you're looking to actual Router routes, go to
 * `routes/index.js`
 */
let languageSelectedEnglish =
  sessionStorage.getItem("language") === "english" ||
  !sessionStorage.getItem("language")
    ? true
    : false;

const sidebar = [
  {
    path: "/admin/dashboard", // the url
    icon: FiGrid, // icon
    name: `${languageSelectedEnglish ? "Dashboard" : language.dashboard}`,
  },
  {
    path: "/admin/buses",
    icon: BsBusFront,
    name: `${languageSelectedEnglish ? "Buses" : language.buses}`,
  },
  {
    path: "/admin/trips",
    icon: BiTrip,
    name: `${languageSelectedEnglish ? "Trips" : language.trips}`,
  },
  {
    path: "/admin/customers",
    icon: AiOutlineUser,

    name: `${languageSelectedEnglish ? "Customers" : language.customers}`,
  },
  {
    path: "/admin/admins",
    icon: FiUsers,
    name: `${languageSelectedEnglish ? "Operators" : language.operators}`,
  },
  // {
  //   path: '/admin/AdminChat',
  //   icon: BsFillChatDotsFill,
  //   name: 'Chat',
  // },
  {
    path: "/admin/bookings",
    icon: FaFileInvoice,
    name: `${languageSelectedEnglish ? "Bookings" : language.bookings}`,
  },
  {
    path: "/admin/coupons",
    icon: FiGift,
    name: `${languageSelectedEnglish ? "Coupons" : language.coupons}`,
  },
  {
    path: "/admin/request",
    icon: MdRequestPage,
    name: `${languageSelectedEnglish ? "Requests" : language.requests}`,
  },

  {
    path: "/admin/profile",
    icon: FiSettings,
    name: `${languageSelectedEnglish ? "Edit Profile" : language.editProfile}`,
  },
];

export default sidebar;
